import { createApp } from 'vue'
import App from './App.vue'
import 'element-plus/dist/index.css'
import router from './router'
import axios from 'axios'
import Toaster from "@meforma/vue-toaster";
import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css'
require('@/styles/dark.css')

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

const app = createApp(App)
app.use(router)
app.use(Toaster)
app.mount('#app')


app.config.globalProperties.BASE_URL = process.env.VUE_APP_FULL_URL;
app.config.globalProperties.API_URL = process.env.VUE_APP_API_URL;
app.config.globalProperties.token = {};

app.config.globalProperties.token.get = function () {
  return localStorage.getItem('apiToken');
}

app.config.globalProperties.token.set = function (token) {
  localStorage.setItem('apiToken', token);
}


axios.interceptors.request.use(function (config) {

  if (app.config.globalProperties.token != null)
    config.headers = { 'Authorization': app.config.globalProperties.token.get() }

  return config;
  }, function (error) {
  // Do something with request error
  return Promise.reject(error);
});


//set global interceptor
axios.interceptors.response.use(function (response) {
  if (response.headers != null && response.headers['x-token'] != null) {
    app.config.globalProperties.token.set(response.headers['x-token']);
  }
  return response;
}, function (error) {
  return Promise.reject(error);
});
