import _createForOfIteratorHelper from "c:/Jenkins/workspace/Wordle UI/Wordle.UI/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import axios from 'axios';
export default {
  data: function data() {
    return {
      drawer: false,
      direction: 'btt',
      player_name: '',
      room_key: ''
    };
  },
  props: {},
  mounted: function mounted() {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      var _iterator = _createForOfIteratorHelper(registrations),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var registration = _step.value;
          registration.unregister();
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
    });
  },
  methods: {
    createRoom: function createRoom(e) {
      var $that = this;
      axios.post('room', {
        name: this.player_name
      }, {
        withCredentials: true
      }).then(function (resp) {
        if (resp.status == 200) $that.$router.push('/room/' + resp.data);
      });
    },
    navigateToRoom: function navigateToRoom() {
      this.$router.push('/room/' + this.room_key);
    }
  }
};